<template lang="pug">
.brand-wrapper.om-wizard-similar-templates
  .container-fluid.position-relative(v-if="!$apollo.loading")
    .row
      .col-12.d-flex.justify-content-start
        div
          om-button.mr-4(primary ghost @click="$router.go(-1)" icon="angle-left-b") {{ $t('back') }}
        wizard-title.text-left.mb-3.pb-3 {{ name }}
    .row(:ref="paletteRef")
      .col-12.col-sm-6.col-md-4.col-xl-3(
        v-if="customThemeTemplatesWithThemeKit.length"
        v-for="templateWithThemeKit in customThemeTemplatesWithThemeKit"
        style="margin-bottom: 1.5rem"
      )
        template-frame.cursor-pointer(
          @observable="addObservable($event.$el)"
          :allowSsr="isTemplatesWithSsr"
          @inited="updateDimensions"
          :dimensions="boxDimensions"
          :template="templateWithThemeKit.template"
          :themeKit="templateWithThemeKit.themeKit"
          @contentLoaded="onContentLoaded"
          @click.native="showModal(templateWithThemeKit.template, templateWithThemeKit.themeKit)"
        )
          template(#fallback)
            template-box.cursor-pointer(
              uniqueColors
              :key="templateWithThemeKit.template._id"
              :template="templateWithThemeKit.template"
              :color="getColor(templateWithThemeKit.template)"
              @click.native="showModal(templateWithThemeKit.template, templateWithThemeKit.themeKit)"
            )
      .col-12.col-sm-6.col-md-4.col-xl-3(
        v-for="template in templates"
        style="margin-bottom: 1.5rem"
      )
        template-frame.cursor-pointer(
          @observable="addObservable($event.$el)"
          :allowSsr="isTemplatesWithSsr"
          @inited="updateDimensions"
          :dimensions="boxDimensions"
          :template="template"
          :themeKit="getCorrectThemeKitForTemplate(template)"
          @contentLoaded="onContentLoaded"
          @click.native="showModal(template, getCorrectThemeKitForTemplate(template))"
        )
          template(#fallback)
            template-box.cursor-pointer(
              uniqueColors
              :key="template._id"
              :template="template"
              :color="getColor(template)"
              @click.native="$modal.show('template-preview', { templateId: template._id, color: getColor(template), theme: template.theme })"
            )
  portal(to="root")
    template-preview
  portal(to="root")
    NewCampaign
</template>

<script>
  import { get as _get } from 'lodash-es';
  import GET_SIMILAR_TEMPLATES from '@/graphql/GetSimilarTemplates.gql';
  import GET_TEMPLATE_THEMES from '@/graphql/GetTemplateThemes.gql';
  import TemplateBox from '@/components/Template/Themes/TemplateBox.vue';
  import TemplatePreview from '@/components/Modals/TemplatePreview';
  import NewCampaign from '@/components/Modals/NewCampaign';
  import TemplateFrame from '@/components/Template/TemplateFrame.vue';
  import { mapGetters } from 'vuex';

  import WizardTitle from '@/components/Wizard/Title.vue';
  import paletteMixin from '@/mixins/palette';
  import previewParentMixin from '@/mixins/previewParent';
  import ssrMixin from '@/mixins/ssr';
  import observableCollectionMixin from '@/mixins/observableCollection';
  import templateRecommendationMixin from '@/mixins/templateRecommendation';
  import GET_THEME_TEMPLATES from '@/graphql/GetThemeTemplates.gql';
  import GET_CUSTOM_THEMES from '@/graphql/GetCustomThemes.gql';

  const TEMPLATE_TYPE_VALUES = {
    popup: 140,
    interstitial: 120,
    sidebar: 100,
    nanobar: 80,
    embedded: 60,
  };

  const DISCOUNT_CODE_REMINDER_USE_CASE_ID = '5fb534910e4fb2001328a153';

  export default {
    components: { TemplateBox, WizardTitle, TemplatePreview, NewCampaign, TemplateFrame },
    mixins: [
      paletteMixin,
      previewParentMixin,
      ssrMixin,
      observableCollectionMixin,
      templateRecommendationMixin,
    ],

    apollo: {
      themes: {
        query: GET_TEMPLATE_THEMES,
      },
      customThemes: {
        query: GET_CUSTOM_THEMES,
        update({ themes }) {
          return themes?.custom || null;
        },
      },
    },

    data() {
      return {
        useCase: {},
        selectedColor: '',
        recommendedUseCases: [],
        moreFromYourStyleTemplates: [],
      };
    },

    computed: {
      ...mapGetters(['wizardPreferences']),
      preferredTheme() {
        return this.wizardPreferences.style || 'Clean';
      },
      preferredThemeTemplates() {
        return this.recommendedUseCases.filter((useCase) =>
          useCase.templates.some((template) => this.preferredTheme === template.theme),
        );
      },
      name() {
        if (this.onMoreFromYourStyleView) {
          return this.$t('templateRecommendation.carousel.moreFromYourStyle');
        }

        return _get(this.useCase, 'name.en');
      },
      templates() {
        const templates = _get(this.useCase, 'templates', this.moreFromYourStyleTemplates);
        templates.sort(({ type: aType = 'embedded' }, { type: bType = 'embedded' }) => {
          const aTypeValue = TEMPLATE_TYPE_VALUES[aType];
          const bTypeValue = TEMPLATE_TYPE_VALUES[bType];
          return bTypeValue - aTypeValue;
        });
        return templates.filter(({ universal, template }) => {
          const canShowToUser = template?.themeKit?.name;
          return !universal || canShowToUser;
        });
      },
      themesByName() {
        const byName = {};
        this.themes.forEach((theme) => {
          if (theme && theme.name) byName[theme.name] = theme;
        });
        return byName;
      },
      useCaseId() {
        const id = this.$route.params.id || this.$route.query.id;
        return id;
      },
      onMoreFromYourStyleView() {
        return this.$route.name === 'more_from_your_style';
      },
      customThemeTemplatesWithThemeKit() {
        if (this.useCaseId !== DISCOUNT_CODE_REMINDER_USE_CASE_ID) return null;
        const templatesWithThemeKit = [];
        this.customThemes.forEach(({ themeKit, templates }) => {
          if (!themeKit) return;
          templates.forEach((template) => {
            if (template.useCase === DISCOUNT_CODE_REMINDER_USE_CASE_ID) {
              templatesWithThemeKit.push({ themeKit, template });
            }
          });
        });
        return templatesWithThemeKit;
      },
      recommendedThemeKit() {
        return (
          this.customThemes?.find?.(({ _id }) => _id === this.$route.query.customTheme) ?? null
        );
      },
    },

    async created() {
      if (!this.onMoreFromYourStyleView) {
        this.useCase = await this.fetchUseCase();

        this.selectedColor = this.$route.query.color;
        this.updatePalette(this.selectedColor);
      } else {
        await this.loadPreferredTemplates();
      }
    },

    methods: {
      async loadPreferredTemplates() {
        const {
          data: { templates },
        } = await this.$apollo.query({
          query: GET_THEME_TEMPLATES,
          variables: {
            theme: this.preferredTheme,
            locale: this.$i18n.locale,
          },
        });

        this.moreFromYourStyleTemplates = templates;
      },

      async fetchUseCase() {
        const {
          data: { useCase },
        } = await this.$apollo.query({
          query: GET_SIMILAR_TEMPLATES,
          variables: {
            id: this.useCaseId,
          },
        });

        return useCase;
      },
      getColor(template) {
        return this.themesByName[template.theme]?.color;
      },
      getCorrectThemeKitForTemplate(template) {
        if (this.recommendedThemeKit?.sourceTheme?.name === template?.theme) {
          return this.recommendedThemeKit?.themeKit;
        }
        return template?.themeKit;
      },

      showModal(template, themeKit) {
        console.log('tpl', template._id, themeKit);
        this.$modal.show('template-preview', {
          templateId: template._id,
          color: themeKit ? undefined : template.universal ? this.$route.query.color : undefined,
          themeKit,
          heapTracking: {
            name: 'template-recommendation-selection-wizard',
            data: {
              similarTemplates: true,
              templateName: template.name,
              theme: template.theme,
              themeKit,
            },
          },
        });
      },
    },
  };
</script>

<style lang="sass">
  .om-wizard-similar-templates
    padding-bottom: 3.5rem

    .brand-themes-template-box
      width: 100%
      padding: 0
</style>
